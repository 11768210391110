import {
  Fragment,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import MUIDataTable from 'mui-datatables';
import {
  Button,
  IconButton,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';

import {
  Label,
  Loader,
  Link,
} from 'components';
import { TABLES_ITEMS_PER_PAGE } from 'settings';
import { pickValue } from 'utils/objects';

import {
  DEFAULT_PROPS,
  PROP_TYPES,
  TEXT_LABELS,
} from './constants';
import {
  formatCell,
  filterListRender,
  downloadExcel,
  getData,
} from './functions';
import useStyles from './styles';

function DataGrid(props) {
  const {
    config,
    customSearch,
    total,
    title,
    serverSide = false,
    allData = [],
  } = props;

  const classes = useStyles();
  const [maxHeight, setMaxHeight] = useState(250);
  const [searchString] = useState();
  const [keyEnter, setKeyEnter] = useState(false);
  const [idTimeOut, setIdTimeOut] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(
    (config.rowsPerPageOptions || TABLES_ITEMS_PER_PAGE)[0],
  );

  const customBodyRender = (value, item) => {
    const {
      cellValue,
      textAlign,
    } = formatCell(item, value);

    return (<div
      children={cellValue}
      className={classes.tableBodyCell}
      style={{
        textAlign,
      }}
    />);
  };

  const getColumns = function ({ actions = [], headers = [], rows = [] }) {
    const columns = [
      ...headers,
      actions.length ? {
        label: 'Acciones',
        name: '__actions',
        type: 'array',
        variant: {
          type: 'component',
          mapping: 'actions',
        },
      } : null,
    ].filter((item) => !!item);

    return columns.map((item) => {
      const columnOptions = {
        label: item.label,
        name: item.name,
        options: {
          customHeadLabelRender: (columnMeta) => {
            const classType = {
              number: 'containerHeaderTextRight',
              string: 'containerHeaderTextLeft',
            }[item?.type] || 'containerHeaderTextCenter';

            return (
              <div className={classes[classType]}>
                <div
                  children={columnMeta.label}
                  className={classes.tableHead}
                />
              </div>
            );
          },
          empty: item?.variant?.mapping === 'actions',
          display: item?.config?.display === undefined
            ? true
            : item.config.display,
          download: item?.variant?.mapping !== 'actions',
          draggable: item?.variant?.mapping !== 'actions',
          filter: item?.variant?.mapping !== 'actions' && (
            item?.config?.filter === undefined
              ? true
              : item.config.filter),
          filterOptions: {
            names: allData.length > 0 
              ? [...new Set(allData.map((data) => data[item.name]))] 
              : [], // Extrae opciones únicas basadas en allData
            renderValue: (value) => filterListRender(value, item),
          },
          filterType: item?.config?.filterType,
          customFilterListOptions: {
            render: (value) => filterListRender(value, item),
          },
          hint: null,
          print: item?.variant?.mapping !== 'actions',
          sort: item?.variant?.mapping !== 'actions',
          sortThirdClickReset: true,
          viewColumns: item?.variant?.mapping !== 'actions',
        },
      };

      if (item.variant?.type === 'component') {
        columnOptions.options.customBodyRenderLite = (dataIndex, rowIndex) => {
          const values = structuredClone(rows[dataIndex]);

          if (item.variant?.mapping === 'actions') {
            return (<div style={{
              textAlign: 'center',
            }}>
              {actions.map((action, actionIndex) => {

                if (typeof action.disabled === 'function') {
                  action.disabled = action.disabled(values, rowIndex);
                }

                if (action.disabled) {
                  action.color = 'default';
                } else if (typeof color === 'function') {
                  action.color = action.color(values, rowIndex);
                }

                if (typeof action.hidden === 'function') {
                  action.hidden = action.hidden(values, rowIndex);
                }

                if (typeof action.icon === 'function') {
                  action.icon = action.icon(values, rowIndex);
                }

                if (typeof action.title === 'function') {
                  action.title = action.title(values, rowIndex);
                }

                const Component = action.title ? Tooltip : Fragment;

                if (action.hidden) {
                  return (<></>);
                }

                return (<Component key={actionIndex} title={action.title}>
                  {action.label
                    ? (<Button
                      startIcon={action.icon}
                      children={action.label}
                      className={clsx(classes[`${action.color}Bg`])}
                      disabled={action.disabled}
                      onClick={() => action.fn(values, dataIndex)}
                      size="small"
                      variant="contained"
                    />)
                    : (<IconButton
                      children={<SvgIcon
                        children={action.icon}
                        className={clsx(classes[action.color])}
                        fontSize="small"
                      />}
                      disabled={action.disabled}
                      onClick={() => action.fn(values, dataIndex)}
                      size="small"
                      variant="outlined"
                    />)}
                </Component>);
              })}</div>);
          } else {
            const Component = {
              label: Label,
              link: Link,
            }[item?.variant?.mapping] || Fragment;
            const {
              cellValue,
              textAlign,
            } = formatCell(item, pickValue(values, item.name));
            let componentProps = item?.variant?.props;

            if (typeof componentProps === 'function') {
              componentProps = componentProps(values);
            }

            return (<div
              children={<Component
                children={cellValue}
                {...componentProps}
              />}
              style={{
                textAlign,
              }}
            />);
          }
        };
      }
      else {
        columnOptions
          .options
          .customBodyRender = (value) => customBodyRender(value, item);
      }

      return columnOptions;
    });
  };

  const getOptions = function ({ events = {} }) {
    return {
      count: total,
      download: config?.download,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      draggableColumns: {
        enabled: true,
      },
      enableNestedDataAccess: '.',
      filter: config?.filter === undefined
        ? true
        : config?.filter,
      filterType: 'multiselect',
      fixedHeader: true,
      fixedSelectColumn: true,
      jumpToPage: true,
      onDownload: (buildHead, buildBody, columns, rows) => {
        if (!config?.replaceDownload) {
          downloadExcel(columns, rows, title);
        }

        if (events.onDownload) {
          events.onDownload(buildHead, buildBody, columns, rows);
        }

        return false;
      },
      onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
        if (events.onFilterChange) {
          events.onFilterChange(
            changedColumn,
            filterList,
            type,
            changedColumnIndex,
            displayData,
          );
        }
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        if (events.onFilterChipClose) {
          events.onFilterChipClose(index, removedFilter, filterList);
        }
      },
      onFilterConfirm: (filterList) => {
        if (events.onFilterConfirm) {
          events.onFilterConfirm(filterList);
        }
      },
      onSearchChange: (searchText) => {
        if (searchText === null) {
          if (typeof customSearch === 'function') {
            customSearch(searchText);
          }
        } else {
          setKeyEnter(false);
          clearTimeout(idTimeOut);

          const regexp = /^([a-zA-Z0-9_-])$/;
          if (regexp.test(searchText.key)) {
            const id = setTimeout(function () {
              setKeyEnter(true);
            }, 300);
            setIdTimeOut(id);
          }

          if (searchText.key === 'Enter') {
            setKeyEnter(true);
          }
        }
      },
      onTableChange: (action, tableState) => {
        if (action === 'changeRowsPerPage') {
          setRowsPerPage(tableState?.rowsPerPage || 25);
        }

        if (events.onTableChange) {
          events.onTableChange(action, tableState);
        }
      },
      onTableInit: (action, tableState) => {
        if (events.onTableInit) {
          events.onTableInit(action, tableState);
        }
      },
      onViewColumnsChange: (changedColumn, action) => {
        if (events.onViewColumnsChange) {
          events.onViewColumnsChange(changedColumn, action);
        }
      },
      pagination: config.pagination === undefined
        ? true :
        config.pagination,
      print: false,
      resizableColumns: config.resizableColumns,
      responsive: config.responsive || 'vertical',
      rowHover: true,
      rowsPerPage,
      rowsPerPageOptions: config.rowsPerPageOptions || TABLES_ITEMS_PER_PAGE,
      search: true,
      searchPlaceholder: customSearch
        ? 'Escriba el criterio y presione [Enter] para buscar'
        : undefined,
      selectableRows: false,
      serverSide,
      setTableProps: () => ({
        size: 'small',
      }),
      tableBodyHeight: `${maxHeight}px`,
      textLabels: TEXT_LABELS,
      viewColumns: true,
      storageKey: config.storageKey
        ? `datagrid_${config.storageKey}`
        : undefined,
    };
  };

  useEffect(() => {
    if (keyEnter) {
      setKeyEnter(false);
      if (typeof customSearch === 'function') {
        customSearch(searchString);
      }
    }
  }, [keyEnter]);

  useEffect(() => {
    const hasPagination = config.pagination === undefined
      ? true :
      config.pagination;
    const offsetTop = (config.offsetTop || 330) + (hasPagination ? 50 : 0);
    const height = window.innerHeight - offsetTop;

    setMaxHeight(height);
  }, []);

  useEffect(() => {
    localStorage.removeItem(`datagrid_${config.storageKey}`);
  }, []);

  return (<>
    {props.isLoading
      ? <Loader variant="card" />
      : <MUIDataTable
        columns={getColumns(props)}
        data={getData(props)}
        options={getOptions(props)}
        title={title}
      />
    }
  </>);
}

DataGrid.defaultProps = DEFAULT_PROPS;

DataGrid.propTypes = PROP_TYPES;

export default DataGrid;
