import {
  useRef,
  useState,
  useMemo,
  useEffect,
  memo,
} from 'react';
import {
  array,
  arrayOf,
  bool,
  element,
  func,
  oneOf,
  shape,
  string,
} from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import Breadcrumb from './Breadcrumb';
import Divider from './Divider';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginRight: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Header(props) {
  const {
    actions,
    backAction,
    breadcrumb,
    breadcrumbPieces,
    tabs,
    title,
    subtitle,
  } = props;
  const classes = useStyles();
  const actionRef = useRef(null);
  const [openMenu, setOpenMenu] = useState({});
  const [menuValue, setMenuValue] = useState({});

  useEffect(() => {
    const initialMenuValue = {};
    actions.forEach((item, index) => {
      if (item.type === 'menu') {
        const selected = item.options.find(({ selected }) => selected);
        if (selected) {
          initialMenuValue[`actions_menu_${index}`] = selected;
        }
      }
    });
    setMenuValue(initialMenuValue);
  }, [actions]);

  const memorizedActions = useMemo(() => {
    return actions.map((item, index) => {
      const {
        processing = false,
        disabled = processing || false,
      } = item;

      if (item.type === 'menu') {
        return (
          <>
            <Button
              children={
                item.alwaysShowLabel
                  ? item.label
                  : menuValue[`actions_menu_${index}`]
                    ? menuValue[`actions_menu_${index}`].label
                    : item.label
              }
              color={item.color}
              endIcon={openMenu[`actions_menu_${index}`]
                ? <ExpandLessIcon />
                : <ExpandMoreIcon />}
              ref={actionRef}
              onClick={() => setOpenMenu({
                ...openMenu,
                [`actions_menu_${index}`]: true,
              })}
              startIcon={item.icon}
              variant="outlined"
              style={{
                display: (item.hidden ? 'none' : undefined),
              }}
            />
            <Menu
              anchorEl={actionRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              getContentAnchorEl={null}
              onClose={() => handleClose(index)}
              open={openMenu[`actions_menu_${index}`]}
              PaperProps={{
                className: classes.menu,
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {item.options.filter((option) => !option.hidden).map((option, i) => (
                <MenuItem
                  key={i}
                  selected={option.selected}
                  onClick={function () {
                    setMenuValue({
                      ...menuValue,
                      [`actions_menu_${index}`]: option,
                    });
                    handleClose(index);

                    if (typeof option.fn === 'function') {
                      option.fn(option);
                    }
                  }}
                >
                  {option.icon && <ListItemIcon children={option.icon} />}
                  <Typography
                    children={option.label}
                    variant="inherit"
                  />
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      } else {
        return (
          <Button
            children={item.label}
            color={item.color || 'secondary'}
            disabled={disabled || processing}
            key={index}
            form={item.form}
            onClick={item.fn}
            startIcon={processing ?
              (<CircularProgress
                color="secondary"
                size={18}
                style={{
                  marginRight: 5,
                }}
              />)
              : item.icon}
            style={{
              ...{
                marginLeft: 5,
                marginRight: 5,
              },
              display: (item.hidden ? 'none' : undefined),
              ...(item.color === 'error' && !disabled ? {
                color: 'white',
                backgroundColor: red[500],
                '&:hover': {
                  backgroundColor: red[900],
                },
              } : {}),
            }}
            type={item.type}
            variant={item.variant || 'contained'}
          />
        );
      }
    });
  }, [actions, menuValue, openMenu]);

  const handleClose = function (index) {
    setOpenMenu({
      ...openMenu,
      [`actions_menu_${index}`]: false,
    });
  };

  return (<>
    <Grid
      className={classes.root}
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        {breadcrumb && <Breadcrumb
          pieces={breadcrumbPieces}
        />}

        <Typography
          color="textPrimary"
          variant="h3"
        >
          {(backAction && !backAction.hidden) && (<>
            {backAction?.label
              ? (<Button
                children={backAction?.label}
                color="primary"
                onClick={backAction?.fn}
                startIcon={backAction?.icon}
              />)
              : (<IconButton
                children={backAction?.icon}
                color="primary"
                onClick={backAction?.fn}
              />)}
          </>)}
          {title}
        </Typography>

        <Typography
          children={subtitle}
          color="textPrimary"
          variant="h6"
        />
      </Grid>

      {!!actions.length && <Grid item>
        {memorizedActions}
      </Grid>}
    </Grid>

    {(tabs?.items || []).length > 0 && (<Box mt={2}>
      <Tabs
        onChange={(_, value) => tabs.onChange(value)}
        scrollButtons="auto"
        textColor="secondary"
        value={tabs.value}
        variant="scrollable"
      >
        {(tabs?.items || []).map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>)}
  </>);
}

Header.defaultProps = {
  actions: [],
  breadcrumb: true,
  subtitle: '',
};

Header.propTypes = {
  actions: arrayOf(shape({
    color: oneOf([
      'error',
      'default',
      'primary',
      'secondary',
    ]),
    disabled: bool,
    fn: func,
    form: string,
    icon: element,
    label: string,
    type: oneOf([
      'button',
      'menu',
      'submit',
    ]),
    variant: oneOf([
      'contained',
      'outlined',
      'text',
    ]),
    alwaysShowLabel: bool,
  })),
  backAction: shape({
    disabled: bool,
    fn: func,
    hidden: bool,
    icon: element,
    label: string,
  }),
  breadcrumb: bool,
  breadcrumbPieces: array,
  tabs: shape({
    items: arrayOf(shape({
      label: string,
      name: string,
    })),
    onChange: func,
    value: string,
  }),
  title: string,
  subtitle: string,
};

export default memo(Header);
