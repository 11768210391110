import {
  ArtTrack as ArtTrackIcon,
  CardGiftcard as CardGiftcardIcon,
  Description as DescriptionIcon,
  Notifications as NotificationsIcon,
  SupervisedUserCircleOutlined as UserIcon,
} from '@material-ui/icons';

const items = [
  {
    href: '/settings/templates/forms',
    icon: ArtTrackIcon,
    module: 'forms',
    title: 'forms',
  },
  {
    href: '/settings/templates/notifications',
    icon: NotificationsIcon,
    module: 'notificationTypes',
    title: 'notifications',
  },
  {
    href: '/settings/templates/documents',
    icon: DescriptionIcon,
    module: 'documentsTypes',
    title: 'documents',
  },
  {
    href: '/settings/templates/people',
    icon: UserIcon,
    module: 'clientTypes',
    title: 'people_types',
  },
  {
    href: '/settings/templates/products',
    icon: CardGiftcardIcon,
    module: 'productTypes',
    title: 'products',
  },
];

export default items;
