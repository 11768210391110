import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FolderOpen as FolderOpenIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  label: {
    background: theme.palette.background.default,
    marginTop: theme.spacing(15.75),
  },
  attentionBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(0.75),
    marginBottom: theme.spacing(0),
    fontWeight: 'bold',
    color: '#fff',
    width: '310px',
    display: 'flex', // Habilitar Flexbox
    alignItems: 'center', // Centrado vertical
    justifyContent: 'center', // Centrado horizontal
  },
  atention: {
    fontSize: '15px',
    marginTop: theme.spacing(0.25),
  },
  atention2: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.5),
    color: theme.palette.text.secondary,
    fontSize: '13px',
  },
  step: {
    marginBottom: theme.spacing(-0.5),
    color: theme.palette.text.secondary,
    fontSize: '13px',
    lineHeight: 1.5,
    '& span': {
      fontWeight: 'bold', // Para los números
    },
  },
  example: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '13px',
    marginBottom: theme.spacing(1.75),
  },
}));

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

function FileAndHtmlControl(props) {
  const classes = useStyles();
  const [text, setText] = useState('');
  const brackets = '{{nombre_del_campo}}';
  const exampleClient = '{{nombre_del_cliente}}';
  const exampleCurp = '{{curp_del_cliente}}';
  const examplePhone = '{{telefono_del_cliente}}';
  const [currentError, setCurrentError] = useState(false);
  const {
    disabled,
    error,
    events,
    label,
    name,
    size,
    settings,
    onChange,
    defaultFile,
    defaultFileName,
  } = props;
  const {
    accept = [],
    base64 = false,
    multiple = false,
  } = settings;
  const controlId = `control_${name}_${Math.floor(Math.random() * 100000)}`;
  let inputFile;

  const loadDefaultFile = async function () {
    if (defaultFile !== undefined) {
      const renamedFile = new File([defaultFile], defaultFileName, {
        type: defaultFile.type,
        lastModified: defaultFile.lastModified,
      });

      setText(defaultFileName);

      const target = {
        name,
        value: renamedFile,
      };

      onChange({
        target,
      });
  
      if (events?.onChange) {
        events.onChange(target);
      }
    }
  };

  useEffect(() => {
    loadDefaultFile();
  }, [defaultFile]);

  const handleSelectFile = function () {
    inputFile.click();
  };

  const handleFileChange = async function ({ target: { files } }) {
    setCurrentError(false);
    const names = [];
    let filesOutput = [];
    const validExt = accept.map((_) => _.toLowerCase());

    if (!base64) {
      filesOutput = files;
    }

    for await (const file of files) {
      const [, ext] = file
        .name
        .split('.')
        .map((_) => _.toLowerCase());

      names.push(file.name);

      if (base64) {
        filesOutput.push(await toBase64(file));
      }

      if (!currentError && validExt.length) {
        setCurrentError(validExt.includes(ext) ? '' : 'Invalid file type');
      }
    }

    setText(names.join(', '));
    const target = {
      name,
      value: filesOutput[0],
    };

    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  return (
    <FormControl
      disabled={disabled}
      error={currentError}
      fullWidth
      size={size}
      variant="outlined"
    >
      <div className={classes.attentionBox}>
        <Typography variant="body2" className={classes.atention}>Atención: como configurar su plantilla docx</Typography>
      </div>
      <Typography variant="body2" className={classes.atention2}>
        Para su plantilla docx debes:
      </Typography>
      <Typography variant="body2" className={classes.step}>
        <span>1.</span> Guardarla como un <span>archivo Word (.docx)</span>
      </Typography>
      <Typography variant="body2" className={classes.step}>
        <span>2.</span> Remplazar todos los <span>campos dinámicos</span> por <span>{brackets}</span>
      </Typography>
      <Typography variant="body2" className={classes.example}>
        Ejemplo: "Cliente: {exampleClient}
                 Curp del cliente: {exampleCurp}
                 Telefono del cliente: {examplePhone}"
      </Typography>
      <input
        accept={accept.map((_) => `.${_}`).join()}
        hidden
        multiple={multiple}
        onChange={handleFileChange}
        ref={(input) => inputFile = input}
        type="file"
      />
      <OutlinedInput
        readOnly
        id={controlId}
        type="text"
        value={text}
        onClick={handleSelectFile}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              children={<FolderOpenIcon />}
              disabled={disabled}
              edge="end"
            />
          </InputAdornment>
        }
      />
      <InputLabel
        className={classes.label}
        children={label}
        htmlFor={controlId}
        shrink={!!text}
      />
      {currentError && (<FormHelperText children={currentError} />)}
    </FormControl>);
}

FileAndHtmlControl.defaultProps = {
  disabled: false,
  onChange: () => { },
  settings: {},
};

FileAndHtmlControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  settings: PropTypes.shape({
    accept: PropTypes.array,
    base64: PropTypes.bool,
    multiple: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  defaultFile: PropTypes.any,
  defaultFileName: PropTypes.string,
};

export default FileAndHtmlControl;
