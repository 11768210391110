import Dynamicore, { SERVICES } from 'services/dynamicore';
import {
  AWS_BUCKET as BUCKET,
  AWS_REGION as REGION,
} from 'settings/aws';

export async function checkKeyExist(path, repository = BUCKET) {
  path = path.replace(`https://${repository}.s3.${REGION}.amazonaws.com/`, '');
  const [company, ...key] = path.replace('company/', '').split('/');

  const checkFile = await Dynamicore(SERVICES.USERS_CHECK_DOCUMENT).post({
    bucket: repository,
    company,
    key: `/${key.join('/')}`,
  });

  return checkFile;
}

/**
* Download file from AWS S3
*
* @param {string} path
*/
export async function download(path, sign = true) {
  try {

    const url = sign
      ? await getSignedUrl(path)
      : path;

    window.open(url);
  } catch (error) {
    return false;
  }

  return true;
}

export async function download2(path, sign = true) {
  try {

    const url = sign
      ? await getSignedUrl(path)
      : path;

    // Descarga el archivo usando fetch
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error al descargar el archivo: ${response.statusText}`);
    }

    // Convierte la respuesta a un Blob
    const blob = await response.blob();

    // Crea un objeto File a partir del Blob
    const [fileName] = (path.split('/').pop() || '').split('?'); // Extraer el nombre del archivo de la URL
    const file = new File([blob], fileName, {
      type: blob.type,
    });

    return file;
  } catch (error) {
    return false;
  }
}

/**
 * Get signed URL
 *
 * @param {string} path
 */
export async function getSignedUrl(path, expires = 10, repository = BUCKET) {
  path = (path ?? '').replace(`https://${repository}.s3.${REGION}.amazonaws.com/`, '');
  const [company, ...key] = path.replace('company/', '').split('/');

  const url = await Dynamicore(SERVICES.USERS_SIGNIN_DOCUMENT).post({
    bucket: repository,
    company,
    expires,
    key: `/${key.join('/')}`,
  });

  return url;
}

/**
* Upload file to AWS S3
*
* @param {string} path
* @param {(Buffer|Uint8Array|Blob|string|Readable)} file
* @param {object} metadata
*/
export async function upload(path, file, metadata = {}, company) {
  if (path.startsWith('company/')) {
    [, company] = path.split('/');
    path = path.replace(`company/${company}`, '');
  }

  const presigned = await Dynamicore(SERVICES.USERS_PRESIGNIN_DOCUMENT).post({
    bucket: BUCKET,
    company,
    expires: 60 * 10,
    key: path,
  });

  const formData = new FormData();
  Object.entries(presigned.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', new Blob([file]));

  await fetch(presigned.url, {
    method: 'POST',
    body: formData,
  });

  return {
    Location: `https://${BUCKET}.s3.${REGION}.amazonaws.com/${presigned.fields.key}`,
  };
}
