import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { stringify } from 'querystring';
import { w3cwebsocket } from 'websocket';
import { Typography } from '@material-ui/core';

import { Modal } from 'components';
import {
  readModules,
  readPendings,
} from 'redux/actions/notifications';
import {
  getTableData,
  setQueryLoading,
} from 'redux/actions/peopleSat';
import { updateWebSocket } from 'redux/actions/system';
import Dynamicore, { SERVICES } from 'services/dynamicore';
import { WEBSOCKET_URL } from 'settings';
import { getCurrentSessionData } from 'utils/aws/cognito';
import { renderString } from 'utils/strings';
import { numberFormat } from 'utils/formater';

function WssProvider() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState();
  const { accessToken } = getCurrentSessionData();
  const { t } = useTranslation();
  const wssClient = new w3cwebsocket(`${WEBSOCKET_URL}/user?token=${accessToken}`);

  const getAmountLimits = async function (paymentId) {
    try {
      const payment = await Dynamicore(SERVICES.PAYMENTS).get({
        id: paymentId,
      });
      const account = await Dynamicore(SERVICES.ACCOUNTS).get({
        id: payment?.account,
      });
      const people = await Dynamicore(SERVICES.PEOPLE).get({
        id: account?.client,
      });
      const peopleType = await Dynamicore(SERVICES.PEOPLE_TYPES).get({
        id: people?.client_type,
      });
      const companyConstant = ((await Dynamicore(SERVICES.COMPANY_CONSTANTS).get({
        type_id: 34,
      }))?.values ?? []).find((item) => item?.config?.client_type === people?.client_type);

      return {
        companyConstant,
        payment,
        people,
        peopleType,
      };
    } catch {
      return null;
    }
  };

  useEffect(() => {
    wssClient.onopen = () => {
      console.debug('WebSocket client: Connected');
    };

    wssClient.onmessage = ({ data }) => {
      const message = JSON.parse(data);
      let notify = false;

      dispatch(updateWebSocket(message));

      switch (message.type) {
        default: { break; }

        case 'people_sat_query': {
          const client = Number(location.pathname.replace('/people/', ''));
          if (location.hash === '#sat' && client) {
            dispatch(getTableData({
              client,
            }));
          }

          if (message?.name === 'api.sat.finish') {
            dispatch(setQueryLoading(false));
          }

          break;
        }

        case 'update_notifications': {
          dispatch(readPendings());

          notify = false;

          if (message?.id && ['payment_amount_greater'].includes(message?.name)) {
            getAmountLimits()
              .then((res) => {
                if (res) {
                  setModal({
                    data: res,
                    maxWidth: 'xs',
                    open: true,
                    title: 'Alerta por monto',
                    view: message?.name,
                  });
                }
              });
          } else if (message?.id && ['pld_account_movement', 'pld_qdetect_find', 'risk_matrix'].includes(message?.name)) {
            Dynamicore(SERVICES.PEOPLE)
              .get({
                id: message.id,
              }).then(async function (people) {
                if (people?.client_type) {
                  people.client_type = await Dynamicore(SERVICES.PEOPLE_TYPES).get({
                    id: people?.client_type,
                  });
                }

                setModal({
                  data: people,
                  open: true,
                  title: 'Alerta PLD',
                  view: message?.name,
                });
              });
          }else if (message?.id && ['pld_account_movement_preocupante', 'pld_account_movement_relevante', 'pld_account_movement_inusual'].includes(message?.name)) {
            Dynamicore(SERVICES.PEOPLE)
              .get({
                id: message.id,
              }).then(async function (people) {
                if (people?.client_type) {
                  people.client_type = await Dynamicore(SERVICES.PEOPLE_TYPES).get({
                    id: people?.client_type,
                  });
                }

                setModal({
                  data: people,
                  open: true,
                  title: 'Alerta de operaciones',
                  view: message?.name,
                });
              });
          }

          break;
        }

        case 'update_modules': {
          dispatch(readModules());

          break;
        }
      }

      if (notify) {
        enqueueSnackbar(t(`notifications.${message.name}`), {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 5000,
          variant: message.level || 'default',
        });
      }
    };

    wssClient.onclose = () => {
      console.debug('WebSocket client: Disconnected');
    };

    return () => wssClient.close();
  }, []);

  return (<>

    <Modal
      actions={[
        {
          color: 'primary',
          fn: function () {
            setModal();

            history.push(`/payments?${stringify({
              operation: modal?.data?.payment?.operation,
              status: modal?.data?.payment?.status,
            })}`);
          },
          hidden: !['payment_amount_greater'].includes(modal?.view),
          label: 'Ir a pagos',
        },
        {
          color: 'primary',
          fn: function () {
            setModal();
            history.push(`/people/${modal?.data?.id}/pld#peps`);
          },
          hidden: !['pld_account_movement', 'pld_qdetect_find', 'risk_matrix'].includes(modal?.view),
          label: 'Ver detalle',
        },
        {
          color: 'primary',
          fn: function () {
            setModal();
            history.push('/pld/alerts');
          },
          hidden: !['pld_account_movement_preocupante', 'pld_account_movement_relevante', 'pld_account_movement_inusual'].includes(modal?.view),
          label: 'Ver detalle',
        },
      ]}
      events={{
        onClose: setModal,
      }}
      maxWidth={modal?.maxWidth ?? 'sm'}
      open={modal?.open}
      title={modal?.title}
    >

      {['payment_amount_greater'].includes(modal?.view) && (
        <Typography variant="body1">
          Se ha agregado un monto mayor a{' '}
          <b>{numberFormat(modal?.data?.companyConstant?.value, '$')}</b>
          {' '}para el cliente{' '}
          <b>
            {renderString(
              modal?.data?.peopleType?.config?.fullname ?? '',
              modal?.data?.people ?? {},
            )}
          </b>
        </Typography>
      )}

      {['pld_account_movement', 'pld_qdetect_find', 'risk_matrix'].includes(modal?.view) && (
        <Typography variant="body1">
          Se ha recibido una alerta para la persona <b>{renderString(
            modal?.data?.client_type?.config?.fullname ?? '',
            modal?.data ?? {},
          )}</b> en PLD
        </Typography>
      )}

      {['pld_account_movement_preocupante', 'pld_account_movement_relevante', 'pld_account_movement_inusual'].includes(modal?.view) && (
        <Typography variant="body1">
          Se ha recibido una alerta de una operation {modal?.view?.split('_').pop()} para la persona <b>{renderString(
            modal?.data?.client_type?.config?.fullname ?? '',
            modal?.data ?? {},
          )}</b> en PLD
        </Typography>
      )}
    </Modal>

  </>);
}

export default WssProvider;
